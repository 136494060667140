// reactstrap components
import React from "react";

import { Card, CardHeader, CardBody, Row, Col } from "reactstrap";
import { useSelector } from "react-redux";
import { Hash } from "react-feather";

const History = () => {
  const [data, setData] = React.useState([]);
  const dataes = useSelector((state) => state.Get.dataes);
  React.useMemo(() => {
    if (dataes != null) {
      // console.log(dataes.history);
      setData(dataes.service);
    }
  }, [dataes]);

  return (
    <>
      <Col xs={12} sm={12} md={12} lg={7} xl={7}>
        <Card className="card-bg-gold shadow border-0">
          <CardHeader className="bg-transparent">
            <Row className="justify-content-center">
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="text-center"
              >
                <h1 className="text-dark font-weight-light">บริการของเรา</h1>
              </Col>
            </Row>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            {data.map((item, keys) => (
              <div key={keys}>
                <Row>
                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="text-left"
                  >
                    <h3 className="text-dark font-weight-light ml-5">
                      <Hash className="align-middle mb-1" size={18} />
                      &nbsp; {item.title}
                    </h3>
                  </Col>
                </Row>
                <hr />
              </div>
            ))}
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default History;
