import axios from "axios";

async function ApiLanding() {
  const request = await axios({
    method: "get",
    url: global.server_url + "api/landing",
  })
    .then(function (response) {
      if (response.status == 200) {
        // console.log(response);
        return response.data;
      } else {
        console.log(response);
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  return request;
}
async function CheckToken() {
  const result = await axios({
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
    method: "get",
    url: global.server_url + "api/auth/me",
  })
    .then(function (response) {
      if (response.status == 200) {
        return true;
      } else {
        localStorage.clear();
        return false;
      }
    })
    .catch(function (error) {
      localStorage.clear();
      return false;
    });
  return result;
}

async function ApiLogin(url, mothod, data) {
  const request = await axios({
    method: mothod,
    url: global.server_url + url,
    data: data,
  })
    .then(function (response) {
      if (response.status == 200) {
        // console.log(response);
        return response.data;
      }
    })
    .catch(function (error) {
      return error.response.status;
    });
  return request;
}

async function ApiGetData(url, mothod, id, data) {
  const request = await axios({
    method: mothod,
    url:
      id == undefined
        ? global.server_url + url
        : global.server_url + url + "/" + id,
    data: data,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then(function (response) {
      if (response.status == 200) {
        // console.log(response);
        return response.data;
      } else {
        console.log(response);
      }
    })
    .catch(function (error) {
      if (error.response.status == 401) {
        localStorage.clear();
        window.location.pathname = "/";
        return error.response.status;
      } else {
        console.log(error);
      }
    });
  return request;
}

async function ApiSubmitData(url, mothod, id, data) {
  if (CheckToken()) {
    const request = await axios({
      method: mothod,
      url:
        id == undefined
          ? global.server_url + url
          : global.server_url + url + "/" + id,
      data: data,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        // console.log(response);
        return response.status;
      })
      .catch(function (error) {
        if (error.response.status == 401) {
          localStorage.clear();
          window.location.pathname = "/";
          return error.response.status;
        } else {
          console.log(error);
        }
      });
    return request;
  }
}

async function ApiUploadFiles(url, mothod, id, data) {
  if (CheckToken()) {
    const request = await axios({
      method: mothod,
      url:
        id == undefined
          ? global.server_url + url
          : global.server_url + url + "/" + id,
      data: data,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        // console.log(response);
        return response;
      })
      .catch(function (error) {
        if (error.response.status == 401) {
          localStorage.clear();
          window.location.pathname = "/";
          return error.response.status;
        } else {
          console.log(error);
        }
      });
    return request;
  }
}

async function ApiChangeImgFirst(url, mothod, id, data) {
  if (CheckToken()) {
    const request = await axios({
      method: mothod,
      url:
        id == undefined
          ? global.server_url + url
          : global.server_url + url + "/" + id,
      data: data,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        // console.log(response);
        return response;
      })
      .catch(function (error) {
        if (error.response.status == 401) {
          localStorage.clear();
          window.location.pathname = "/";

          return error.response.status;
        } else {
          console.log(error);
        }
      });
    return request;
  }
}

async function ApiDeleteFile(url, mothod, id, data) {
  if (CheckToken()) {
    const request = await axios({
      method: mothod,
      url:
        id == undefined
          ? global.server_url + url
          : global.server_url + url + "/" + id,
      data: data,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        // console.log(response);
        return response;
      })
      .catch(function (error) {
        if (error.response.status == 401) {
          localStorage.clear();
          window.location.pathname = "/";

          return error.response.status;
        } else {
          console.log(error);
        }
      });
    return request;
  }
}

export {
  ApiLanding,
  ApiLogin,
  ApiGetData,
  ApiSubmitData,
  ApiUploadFiles,
  ApiChangeImgFirst,
  ApiDeleteFile,
};
