import React from "react";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Col,
  Row,
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from "reactstrap";

// const ExampleModal(props) {
const ExampleModal = ({ actionModal }) => {
  const [modal, setModal] = React.useState(false);
  const [action, setAction] = React.useState("Edit");
  const [name, setName] = React.useState("");
  const [slide_imgs, setSlide_imgs] = React.useState([]);
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === slide_imgs.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === 0 ? slide_imgs.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  async function checkAction() {
    setSlide_imgs([]);

    if (actionModal.action == "Edit") {
      setAction(actionModal.action);
      setName(actionModal.name);
      // console.log(actionModal.img);
      setSlide_imgs(actionModal.img);
      setModal(actionModal.modal);
    }
  }

  React.useMemo(() => {
    if (actionModal.modal) {
      checkAction();
    }
    return () => {};
  }, [actionModal]); // Only re-run the effect if count changes

  return (
    <Modal
      isOpen={modal}
      toggle={() => setModal(!modal)}
      centered={true}
      size="xl"
    >
      <ModalHeader toggle={() => setModal(!modal)}>
        {action == "Edit" ? "ดู" : ""}ข้อมูล: {name}
      </ModalHeader>
      <ModalBody>
        <div>
          <Row className="mt-2 text-center">
            {slide_imgs.length > 0 ? (
              <Col
                md={12}
                style={{
                  width: "100%",
                }}
              >
                {/* <PictureSlider /> */}
                <Carousel
                  activeIndex={activeIndex}
                  next={next}
                  previous={previous}
                >
                  <CarouselIndicators
                    items={slide_imgs}
                    activeIndex={activeIndex}
                    onClickHandler={goToIndex}
                  />
                  {slide_imgs.map((item) => {
                    return (
                      <CarouselItem
                        onExiting={() => setAnimating(true)}
                        onExited={() => setAnimating(false)}
                        key={item.id}
                      >
                        <img
                          className="img-thumbnail img-responsive mt-2"
                          // style={{ cursor: "pointer" }}
                          src={item.src}
                          alt={item.altText}
                          // onClick={() => {
                          //   // console.log(Img);
                          //   window.open(item.src, "_blank");
                          // }}
                        />

                        <CarouselCaption
                          captionText={item.caption || ""}
                          captionHeader={item.header}
                        />
                      </CarouselItem>
                    );
                  })}
                  <CarouselControl
                    direction="prev"
                    directionText="Previous"
                    onClickHandler={previous}
                  />
                  <CarouselControl
                    direction="next"
                    directionText="Next"
                    onClickHandler={next}
                  />
                </Carousel>
              </Col>
            ) : null}
          </Row>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ExampleModal;
