import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

// reactstrap components
import {
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";

const AdminNavbar = () => {
  const dataes = useSelector((state) => state.Get.dataes);

  const [data, setData] = React.useState({});
  React.useMemo(() => {
    if (dataes != null) {
      setData(dataes.home);
    }
  }, [dataes]);

  return (
    <>
      <Navbar className="navbar-top navbar-horizontal navbar-dark" expand="md">
        <Container>
          <NavbarBrand to="/landing/home" tag={Link}>
            {data.img == null ? null : (
              <img
                style={{ width: "40px", height: "40px" }}
                alt="..."
                // src={require("../../assets/img/brand/logo.png").default}
                src={global.server_url + data.img}
              />
            )}
            &nbsp;
            <span className="text-dark" style={{ fontSize: "12px" }}>
              {/* C&P THAWEECHOK CO..LTD. */}
              {data.text_logo}
            </span>
          </NavbarBrand>
          <button className="navbar-toggler" id="navbar-collapse-main">
            <span className="navbar-toggler-icon" />
          </button>
          <UncontrolledCollapse
            navbar
            toggler="#navbar-collapse-main"
            defaultOpen={false}
          >
            <div className="navbar-collapse-header d-md-none">
              <Row>
                <Col
                  className="collapse-brand"
                  xs={10}
                  sm={10}
                  md={10}
                  lg={10}
                  xl={10}
                >
                  <div to="/landing/home">
                    {data.img == null ? null : (
                      <img
                        style={{ width: "30px", height: "30px" }}
                        alt="..."
                        // src={require("../../assets/img/brand/logo.png").default}
                        src={global.server_url + data.img}
                      />
                    )}
                    &nbsp;
                    <span style={{ fontSize: "1vh" }}>
                      {/* C&P THAWEECHOK CO..LTD. */}
                      {data.text_logo}
                    </span>
                  </div>
                </Col>
                <Col
                  className="collapse-close"
                  xs={2}
                  sm={2}
                  md={2}
                  lg={2}
                  xl={2}
                >
                  <button className="navbar-toggler" id="navbar-collapse-main">
                    <span />
                    <span />
                  </button>
                </Col>
              </Row>
            </div>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <NavLink
                  className="nav-link-icon"
                  to="/landing/home"
                  tag={Link}
                >
                  <i className="ni ni-shop text-muted" />
                  <span className="nav-link-inner--text text-dark">
                    {data.text_home}
                  </span>
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className="nav-link-icon"
                  to="/landing/history"
                  tag={Link}
                >
                  <i className="ni ni-album-2 text-muted" />
                  <span className="nav-link-inner--text text-dark">
                    {data.text_historie}
                  </span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className="nav-link-icon"
                  to="/landing/vision"
                  tag={Link}
                >
                  <i className="ni ni-world-2 text-muted" />
                  <span className="nav-link-inner--text text-dark">
                    {data.text_vision}
                  </span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className="nav-link-icon"
                  to="/landing/service"
                  tag={Link}
                >
                  <i className="ni ni-settings text-muted" />
                  <span className="nav-link-inner--text text-dark">
                    {data.text_service}
                  </span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className="nav-link-icon"
                  to="/landing/document"
                  tag={Link}
                >
                  <i className="ni ni-paper-diploma text-muted" />
                  <span className="nav-link-inner--text text-dark">
                    {data.text_document}
                  </span>
                </NavLink>
              </NavItem>
            </Nav>
          </UncontrolledCollapse>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
