import React from "react";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Col,
  FormGroup,
  Label,
  Row,
  ModalFooter,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";
import { ApiGetData, ApiSubmitData } from "../../services/api";
// const ExampleModal(props) {
const ExampleModal = ({ actionModal, callBack }) => {
  const [id, setID] = React.useState("");
  const [action, setAction] = React.useState("");
  const [modal, setModal] = React.useState(false);
  const [buttonSubmit, setButtonSubmit] = React.useState(false);
  const [formData, setFormData] = React.useState({});

  async function checkAction() {
    setFormData({});

    if (actionModal.action == "Add") {
      setFormData({
        name: "",
        active: 1,
      });
    } else if (actionModal.action == "Edit") {
      const response = await ApiGetData(
        "api/group",
        "get",
        actionModal.id,
        null
      );
      setFormData(response);
    }
    setID(actionModal.id);
    setAction(actionModal.action);
    setModal(actionModal.modal);
  }

  const handleSubmit = async (event, errors, values) => {
    setButtonSubmit(true);
    if (errors.length > 0) {
    } else {
      if (action == "Add") {
        const response = await ApiSubmitData(
          "api/group",
          "post",
          null,
          formData
        );
        if (response == 200) {
          callBack("Success");
        } else {
          callBack("Error");
        }
      } else if (action == "Edit") {
        const response = await ApiSubmitData(
          "api/group",
          "put",
          id,
          formData
        );
        if (response === 200) {
          callBack("Success");
        } else {
          callBack("Error");
        }
      }
      setModal(!modal);
    }
    setButtonSubmit(false);
  };

  React.useMemo(() => {
    if (actionModal.modal) {
      checkAction();
    }
    return () => {};
  }, [actionModal]); // Only re-run the effect if count changes

  return (
    <Modal
      isOpen={modal}
      toggle={() => setModal(!modal)}
      centered={true}
      size="md"
    >
      <ModalHeader toggle={() => setModal(!modal)}>
        {action == "Edit" ? "แก้ไข" : "เพิ่ม"}กลุ่ม{" "}
      </ModalHeader>
      <AvForm onSubmit={handleSubmit} model={formData}>
        <ModalBody>
          <Row>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <FormGroup>
                <AvField
                  name="name"
                  type="text"
                  placeholder="ชื่อกลุ่ม"
                  value={formData.name}
                  onChange={(e) =>
                    setFormData({ ...formData, name: e.target.value })
                  }
                  validate={{
                    required: { value: true },
                  }}
                />
              </FormGroup>
            </Col>

            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <FormGroup className="mt-2">
                <FormGroup check inline>
                  <Label check>
                    <Input
                      type="radio"
                      name="radioActive"
                      checked={formData.active == 1}
                      value={1}
                      onChange={(e) =>
                        setFormData({ ...formData, active: e.target.value })
                      }
                    />
                    เปิดใช้งาน
                  </Label>
                </FormGroup>
                <FormGroup check inline>
                  <Label>
                    <Input
                      type="radio"
                      name="radioActive"
                      checked={formData.active == 2}
                      value={2}
                      onChange={(e) =>
                        setFormData({ ...formData, active: e.target.value })
                      }
                    />
                    ปิดใช้งาน
                  </Label>
                </FormGroup>
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button type="submit" disabled={buttonSubmit} color="secondary">
            บันทึก
          </Button>
        </ModalFooter>
      </AvForm>
    </Modal>
  );
};

export default ExampleModal;
