import Index from "views/Index";

import sHistory from "views/settings/History";
import sVision from "views/settings/Vision";
import sService from "views/settings/Service";
import sGroup from "views/settings/Group";
import sResume from "views/settings/Resume";
import sDocument from "views/settings/Document";
import sUser from "views/settings/User";

import Login from "views/landings/Login";
import Home from "views/landings/Home";
import History from "views/landings/History";

import Vision from "views/landings/Vision";
import Service from "views/landings/Service";
import Document from "views/landings/Document";

var routes = [
  {
    path: "/index",
    name: "หน้าแรก",
    icon: "ni ni-shop text-dark",
    component: Index,
    layout: "/admin",
  },

  {
    path: "/history",
    name: "ประวัติ",
    icon: "ni ni-album-2 text-dark",
    component: sHistory,
    layout: "/admin",
  },
  {
    path: "/vision",
    name: "วิสัยทัศน์ พันทกิจ",
    icon: "ni ni-world-2 text-dark",
    component: sVision,
    layout: "/admin",
  },
  {
    path: "/service",
    name: "บริการของเรา",
    icon: "ni ni-settings text-dark",
    component: sService,
    layout: "/admin",
  },
  {
    path: "/document",
    name: "เอกสารบริษัท",
    icon: "ni ni-paper-diploma text-dark",
    component: sDocument,
    layout: "/admin",
  },

  {
    path: "/group",
    name: "กลุ่มผลงาน",
    icon: "ni ni-ungroup text-dark",
    component: sGroup,
    layout: "/admin",
  },

  {
    path: "/resume",
    name: "ผลงาน",
    icon: "ni ni-bullet-list-67 text-dark",
    component: sResume,
    layout: "/admin",
  },

  {
    path: "/user",
    name: "ผู้ใช้งาน",
    icon: "ni ni-single-02 text-dark",
    component: sUser,
    layout: "/admin",
  },

  {
    path: "/user",
    name: "ผู้ใช้งาน",
    icon: "ni ni-single-02 text-dark",
    component: sUser,
    layout: "/admin",
  },

  {
    path: "/login",
    name: "Login",
    icon: "ni ni-shop text-dark",
    component: Login,
    layout: "/landing",
  },

  {
    path: "/home",
    name: "Home",
    icon: "ni ni-shop text-dark",
    component: Home,
    layout: "/landing",
  },

  {
    path: "/history",
    name: "History",
    icon: "ni ni-badge text-dark",
    component: History,
    layout: "/landing",
  },
  {
    path: "/vision",
    name: "Vision",
    icon: "ni ni-planet text-dark",
    component: Vision,
    layout: "/landing",
  },
  {
    path: "/service",
    name: "Service",
    icon: "ni ni-settings text-dark",
    component: Service,
    layout: "/landing",
  },
  {
    path: "/document",
    name: "Document",
    icon: "ni ni-paper-diploma text-dark",
    component: Document,
    layout: "/landing",
  },
];
export default routes;
