// reactstrap components
import { Container, Row, Col, Nav, NavItem, NavLink } from "reactstrap";

const Footer = () => {
  return (
    <footer className="footer">
      <Row className="align-items-center justify-content-xl-between">
        <Col xl="6"></Col>
        <Col xl="6">
          <div className="copyright text-right text-xl-right text-muted">
            © {new Date().getFullYear()}&nbsp;
            {/* <a
              className="font-weight-bold ml-1"
              href="https://www.creative-tim.com?ref=adr-admin-footer"
              rel="noopener noreferrer"
              target="_blank"
            > */}
            C&P THAWEECHOK
            {/* </a> */}
          </div>
        </Col>
      </Row>
    </footer>
  );
};

export default Footer;
