/*eslint-disable*/

// reactstrap components
import {
  Card,
  CardBody,
  FormGroup,
  Container,
  Row,
  Col,
  Button,
  Input,
  Label,
} from "reactstrap";
// core components
import UserHeader from "components/Headers/UserHeader.js";
import React from "react";
import { ApiGetData, ApiSubmitData } from "../services/api";
import * as Alert from "../customJS/customAlert";
import { AvForm, AvField } from "availity-reactstrap-validation";
const Index = () => {
  const [formData, setFormData] = React.useState({});
  const [buttonSubmit, setButtonSubmit] = React.useState(false);
  const [img, setImg] = React.useState(null);
  const [dataImg, setDataImg] = React.useState(null);
  const [img_text, setImg_text] = React.useState(null);
  const [dataImg_text, setDataImg_text] = React.useState(null);

  React.useMemo(() => {
    fetchData();
  }, []);

  async function handleSubmit(event, errors, values) {
    setButtonSubmit(true);

    if (errors.length > 0) {
    } else {
      const data = new FormData();
      data.append("formData", JSON.stringify(formData));
      data.append("img", dataImg);
      data.append("img_text", dataImg_text);
      data.append("_method", "PATCH");
      const result = await ApiSubmitData("api/home", "post", 1, data);
      if (result == 200) {
        Alert.Top("top-end", "success", "สำเร็จ");
      } else {
        Alert.Top("top-end", "error", "ล้มเหลว");
      }
      fetchData();
    }
    setButtonSubmit(false);
  }

  async function fetchData() {
    await ApiGetData("api/home", "get", 1, null)
      .then(function (response) {
        // console.log(response);
        setImg(global.server_url + response.img_path);
        setImg_text(global.server_url + response.img_text_path);
        let { img_path, img_text_path, ...rest } = response;
        setFormData(rest);
      })
      .catch(function (error) {});
  }

  function onChangePicture(e, type) {
    if (type == "img") {
      if (e.target.files[0]) {
        // console.log("picture: ", e.target.files);
        setDataImg(e.target.files[0]);
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          setImg(reader.result);
        });
        reader.readAsDataURL(e.target.files[0]);
      }
    } else {
      if (e.target.files[0]) {
        // console.log("picture: ", e.target.files);
        setDataImg_text(e.target.files[0]);
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          setImg_text(reader.result);
        });
        reader.readAsDataURL(e.target.files[0]);
      }
    }
  }

  return (
    <>
      <UserHeader />
      <Container className="mt--7" fluid>
        <AvForm onSubmit={handleSubmit} model={formData}>
          <Card className="bg-secondary shadow">
            <CardBody>
              <Row>
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="text-center"
                >
                  <Label for="img">รูปภาพมุมบนซ้าย 1 รูป &nbsp;(40*40)</Label>

                  {img == undefined ? null : (
                    <div>
                      <br />
                      <img
                        src={img}
                        onClick={() => {
                          // console.log(Img);
                          window.open(img, "_blank");
                        }}
                        className="img-thumbnail  img-responsive mt-2"
                        style={{ cursor: "pointer" }}
                        width="40px"
                        height="40px"
                      />
                    </div>
                  )}

                  <Input
                    accept="image/*"
                    type="file"
                    name="img"
                    onChange={(e) => onChangePicture(e, "img")}
                  />
                </Col>
              </Row>
              <hr />

              <Row>
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="text-center"
                >
                  <Label for="img_text">
                    รูปภาพตรงกลาง 1 รูป &nbsp;(250*250)
                  </Label>

                  {img_text == undefined ? null : (
                    <div>
                      <br />
                      <img
                        src={img_text}
                        onClick={() => {
                          // console.log(Img);
                          window.open(img_text, "_blank");
                        }}
                        className="img-thumbnail  img-responsive mt-2"
                        style={{ cursor: "pointer" }}
                        width="250px"
                        height="250px"
                      />
                    </div>
                  )}

                  <Input
                    accept="image/*"
                    type="file"
                    name="img_text"
                    onChange={(e) => onChangePicture(e, "img_text")}
                  />
                </Col>
              </Row>
              <hr />
              <Row className="mt-5">
                <Col
                  xs={12}
                  sm={12}
                  md={6}
                  lg={12}
                  xl={12}
                  className="text-center"
                >
                  <h4>การติดต่อ</h4>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                  <FormGroup>
                    <AvField
                      name="tel"
                      type="text"
                      placeholder="tel"
                      value={formData.tel || ""}
                      onChange={(e) =>
                        setFormData({ ...formData, tel: e.target.value })
                      }
                      validate={{
                        required: { value: false },
                      }}
                    />
                  </FormGroup>
                </Col>

                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                  <FormGroup>
                    <AvField
                      name="googlemap"
                      type="text"
                      placeholder="googlemap"
                      value={formData.googlemap || ""}
                      onChange={(e) =>
                        setFormData({ ...formData, googlemap: e.target.value })
                      }
                      validate={{
                        required: { value: false },
                      }}
                    />
                  </FormGroup>
                </Col>

                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                  <FormGroup>
                    <AvField
                      name="facebook"
                      type="text"
                      placeholder="facebook"
                      value={formData.facebook || ""}
                      onChange={(e) =>
                        setFormData({ ...formData, facebook: e.target.value })
                      }
                      validate={{
                        required: { value: false },
                      }}
                    />
                  </FormGroup>
                </Col>

                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                  <FormGroup>
                    <AvField
                      name="youtube"
                      type="text"
                      placeholder="youtube"
                      value={formData.youtube || ""}
                      onChange={(e) =>
                        setFormData({ ...formData, youtube: e.target.value })
                      }
                      validate={{
                        required: { value: false },
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <hr />
              <Row className="mt-5">
                <Col
                  xs={12}
                  sm={12}
                  md={6}
                  lg={12}
                  xl={12}
                  className="text-center"
                >
                  <h4>ตั้งค่าตัวหนังสือด้านบน</h4>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                  <FormGroup>
                    <AvField
                      name="text_logo"
                      type="text"
                      placeholder="text_logo"
                      value={formData.text_logo || ""}
                      onChange={(e) =>
                        setFormData({ ...formData, text_logo: e.target.value })
                      }
                      validate={{
                        required: { value: true },
                      }}
                    />
                  </FormGroup>
                </Col>

                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                  <FormGroup>
                    <AvField
                      name="text_home"
                      type="text"
                      placeholder="text_home"
                      value={formData.text_home || ""}
                      onChange={(e) =>
                        setFormData({ ...formData, text_home: e.target.value })
                      }
                      validate={{
                        required: { value: true },
                      }}
                    />
                  </FormGroup>
                </Col>

                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                  <FormGroup>
                    <AvField
                      name="text_historie"
                      type="text"
                      placeholder="text_historie"
                      value={formData.text_historie || ""}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          text_historie: e.target.value,
                        })
                      }
                      validate={{
                        required: { value: true },
                      }}
                    />
                  </FormGroup>
                </Col>

                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                  <FormGroup>
                    <AvField
                      name="text_vision"
                      type="text"
                      placeholder="text_historie"
                      value={formData.text_historie || ""}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          text_historie: e.target.value,
                        })
                      }
                      validate={{
                        required: { value: true },
                      }}
                    />
                  </FormGroup>
                </Col>

                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                  <FormGroup>
                    <AvField
                      name="text_service"
                      type="text"
                      placeholder="text_service"
                      value={formData.text_service || ""}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          text_service: e.target.value,
                        })
                      }
                      validate={{
                        required: { value: true },
                      }}
                    />
                  </FormGroup>
                </Col>

                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                  <FormGroup>
                    <AvField
                      name="text_document"
                      type="text"
                      placeholder="text_document"
                      value={formData.text_document || ""}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          text_document: e.target.value,
                        })
                      }
                      validate={{
                        required: { value: true },
                      }}
                    />
                  </FormGroup>
                </Col>

                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                  <FormGroup>
                    <AvField
                      name="text_title"
                      type="text"
                      placeholder="text_title"
                      value={formData.text_title || ""}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          text_title: e.target.value,
                        })
                      }
                      validate={{
                        required: { value: true },
                      }}
                    />
                  </FormGroup>
                </Col>

                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <FormGroup>
                    <AvField
                      name="text_sub_title"
                      type="textarea"
                      row="2"
                      placeholder="text_sub_title"
                      value={formData.text_sub_title || ""}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          text_sub_title: e.target.value,
                        })
                      }
                      validate={{
                        required: { value: true },
                      }}
                    />
                  </FormGroup>
                </Col>

                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                  <FormGroup className="mt-2">
                    <FormGroup check inline>
                      <Label check>
                        <Input
                          type="radio"
                          name="text_type"
                          checked={formData.text_type == 1}
                          value={1}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              text_type: e.target.value,
                            })
                          }
                        />
                        แสดงรูปบนข้อความ
                      </Label>
                    </FormGroup>
                    <FormGroup check inline>
                      <Label>
                        <Input
                          type="radio"
                          name="text_type"
                          checked={formData.text_type == 2}
                          value={2}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              text_type: e.target.value,
                            })
                          }
                        />
                        แสดงรูปล่างข้อความ
                      </Label>
                    </FormGroup>
                  </FormGroup>
                </Col>

                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                  <FormGroup className="mt-2">
                    <FormGroup check inline>
                      <Label check>
                        <Input
                          type="radio"
                          name="show_img_text"
                          checked={formData.show_img_text == 1}
                          value={1}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              show_img_text: e.target.value,
                            })
                          }
                        />
                        แสดงรูป
                      </Label>
                    </FormGroup>
                    <FormGroup check inline>
                      <Label>
                        <Input
                          type="radio"
                          name="show_img_text"
                          checked={formData.show_img_text == 2}
                          value={2}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              show_img_text: e.target.value,
                            })
                          }
                        />
                        ไม่แสดง
                      </Label>
                    </FormGroup>
                  </FormGroup>
                </Col>
              </Row>
              <hr />

              <Row>
                <Col
                  xs={12}
                  sm={12}
                  md={6}
                  lg={12}
                  xl={12}
                  className="text-center"
                >
                  <h4>ตั้งค่าตัวหนังสือด้านล่าง</h4>
                </Col>

                <Col xs={12} sm={12} md={6} lg={12} xl={12}>
                  <FormGroup>
                    <AvField
                      name="text_address"
                      type="textarea"
                      row="2"
                      placeholder="text_address"
                      value={formData.text_address || ""}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          text_address: e.target.value,
                        })
                      }
                      validate={{
                        required: { value: true },
                      }}
                    />
                  </FormGroup>
                </Col>

                <Col xs={12} sm={12} md={6} lg={12} xl={12}>
                  <FormGroup>
                    <AvField
                      name="text_footer"
                      type="text"
                      placeholder="text_footer"
                      value={formData.text_footer || ""}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          text_footer: e.target.value,
                        })
                      }
                      validate={{
                        required: { value: true },
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <div className="text-right">
                    <Button
                      disabled={buttonSubmit}
                      className="my-4"
                      color="primary"
                      type="submit"
                      size="md"
                      outline
                    >
                      บันทึก
                    </Button>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </AvForm>
      </Container>
    </>
  );
};
export default Index;
