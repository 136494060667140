import React from "react";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Col,
  Row,
  ModalFooter,
  Label,
  FormGroup,
} from "reactstrap";

import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";
import { ApiGetData, ApiSubmitData } from "../../services/api";

import * as Alert from "../../customJS/customAlert";
// const ExampleModal(props) {
const ExampleModal = ({ actionModal, callBack }) => {
  const [id, setID] = React.useState("");
  const [action, setAction] = React.useState("");
  const [modal, setModal] = React.useState(false);
  const [buttonSubmit, setButtonSubmit] = React.useState(false);
  const [formData, setFormData] = React.useState({});
  const [img, setImg] = React.useState(null);
  const [dataImg, setDataImg] = React.useState(null);

  async function checkAction() {
    setFormData({});

    if (actionModal.action == "Add") {
      setFormData({
        active: 1,
      });
    } else if (actionModal.action == "Edit") {
      const response = await ApiGetData(
        "api/document",
        "get",
        actionModal.id,
        null
      );
      if (response.img != null) {
        setImg(global.server_url + response.path_img);
      }
      let { path_img, ...rest } = response;
      setFormData(rest);
    }
    setID(actionModal.id);
    setAction(actionModal.action);
    setModal(actionModal.modal);
  }

  const handleSubmit = async (event, errors, values) => {
    setButtonSubmit(true);
    if (errors.length > 0) {
    } else {
      const data = new FormData();
      data.append("formData", JSON.stringify(formData));
      data.append("img", dataImg);
      if (action == "Add") {
        const response = await ApiSubmitData(
          "api/document",
          "post",
          null,
          data
        );
        if (response == 200) {
          callBack("Success");
        } else {
          callBack("Error");
        }
      } else if (action == "Edit") {
        data.append("_method", "PATCH");
        const response = await ApiSubmitData("api/document", "post", id, data);
        if (response === 200) {
          callBack("Success");
        } else {
          callBack("Error");
        }
      }
      setModal(!modal);
    }
    setButtonSubmit(false);
  };

  React.useMemo(() => {
    if (actionModal.modal) {
      checkAction();
    }
    return () => {};
  }, [actionModal]); // Only re-run the effect if count changes

  function onChangePicture(e) {
    if (e.target.files[0]) {
      // console.log("picture: ", e.target.files);
      setDataImg(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImg(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  return (
    <Modal
      isOpen={modal}
      toggle={() => setModal(!modal)}
      centered={true}
      size="md"
    >
      <ModalHeader toggle={() => setModal(!modal)}>
        {action == "Edit" ? "แก้ไข" : "เพิ่ม"}ข้อมูล
      </ModalHeader>
      <AvForm onSubmit={handleSubmit} model={formData}>
        <ModalBody>
          <Row>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="text-center"
            >
              <Label for="img">รูปภาพ&nbsp;(250*250)</Label>

              {img == undefined ? null : (
                <div>
                  <br />
                  <img
                    src={img}
                    onClick={() => {
                      // console.log(Img);
                      window.open(img, "_blank");
                    }}
                    className="img-thumbnail  img-responsive mt-2"
                    style={{ cursor: "pointer" }}
                    width="250px"
                    height="250px"
                  />
                </div>
              )}

              <Input
                accept="image/*"
                type="file"
                name="img"
                onChange={onChangePicture}
              />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
              <FormGroup>
                <AvField
                  name="name"
                  type="text"
                  placeholder="name"
                  value={formData.name || ""}
                  onChange={(e) =>
                    setFormData({ ...formData, name: e.target.value })
                  }
                  validate={{
                    required: { value: true },
                  }}
                />
              </FormGroup>
            </Col>

            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
              <FormGroup className="mt-2">
                <FormGroup check inline>
                  <Label check>
                    <Input
                      type="radio"
                      name="active"
                      checked={formData.active == 1}
                      value={1}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          active: e.target.value,
                        })
                      }
                    />
                    แสดงรูป
                  </Label>
                </FormGroup>
                <FormGroup check inline>
                  <Label>
                    <Input
                      type="radio"
                      name="active"
                      checked={formData.active == 2}
                      value={2}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          active: e.target.value,
                        })
                      }
                    />
                    ไม่แสดง
                  </Label>
                </FormGroup>
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button type="submit" disabled={buttonSubmit} color="secondary">
            บันทึก
          </Button>
        </ModalFooter>
      </AvForm>
    </Modal>
  );
};

export default ExampleModal;
