import React from "react";
import {
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  CardHeader,
  CardFooter,
  Button,
  Row,
  Col,
} from "reactstrap";

import { PhoneCall, Facebook, Youtube, MapPin } from "react-feather";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import { ApiGetData } from "../../services/api";
import ExampleModal from "../../components/dialogs/dialogImg";
import Select from "react-select";

const Home = () => {
  const [data, setData] = React.useState({});
  const [dataResume, setDataResume] = React.useState([]);
  const [groups, setGroups] = React.useState([]);
  const [group_id, setGroup_id] = React.useState(0);

  const [actionModal, setActionModel] = React.useState({
    action: "Edit",
    name: "",
    img: null,
    modal: false,
  });
  const dataes = useSelector((state) => state.Get.dataes);
  React.useMemo(() => {
    if (dataes != null) {
      // console.log(dataes.group);
      setDataResume(dataes.resume);
      setData(dataes.home);
      if (dataes.group != null) {
        const options = dataes.group.map((d) => ({
          value: d.id,
          label: d.name,
        }));
        setGroups(options);
      }
    }
  }, [dataes]);

  const settings = {
    dots: true,
    infinite: false,
    initialSlide: 0,
  };

  async function openImg(id) {
    const response = await ApiGetData("api/resume", "get", id, null);

    let new_img = [];
    for (let index = 0; index < response.slide_imgs.length; index++) {
      new_img.push({
        id: index,
        src: global.server_url + response.slide_imgs[index].img,
        altText: response.name_group.name,
      });
    }
    setActionModel({
      action: "Edit",
      name: response.name_group.name,
      img: new_img,
      modal: true,
    });
  }

  async function changeGroup(id) {
    const response = await ApiGetData("api/landing/group", "get", id, null);
    // console.log(response);
    setDataResume(response);
    setGroup_id(id);
  }

  return (
    <>
      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
        <ExampleModal actionModal={actionModal} />

        <Card className="card-bg-gold shadow border-0 my-">
          <CardHeader className="bg-transparent">
            <Row className="justify-content-center">
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="text-center"
              >
                {data.text_type == 1 ? (
                  data.img_text == null ? null : data.show_img_text ==
                    2 ? null : (
                    <img
                      style={{
                        width: "180px",
                        height: "250px",
                      }}
                      alt="..."
                      // src={require("../../assets/img/brand/logo.png").default}
                      src={global.server_url + data.img}
                    />
                  )
                ) : null}
                <h1 className="text-dark font-weight-light">
                  {data.text_title}
                </h1>
                <p className="text-lead text-dark">{data.text_sub_title}</p>
                {data.text_type == 2 ? (
                  data.img_text == null ? null : data.show_img_text ==
                    2 ? null : (
                    <img
                      style={{
                        width: "180px",
                        height: "250px",
                      }}
                      alt="..."
                      // src={require("../../assets/img/brand/logo.png").default}
                      src={global.server_url + data.img}
                    />
                  )
                ) : null}
              </Col>
            </Row>
            <Row className="justify-content-center">
              {data.tel == null ? null : (
                <Col xs={6} sm={6} md={6} lg={6} xl={6} className="text-center">
                  <p
                    className="text-lead text-dark"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      // console.log(Img);
                      window.open("tel:+" + data.tel, "_blank");
                    }}
                  >
                    <PhoneCall className="align-middle" size={18} />
                    &nbsp; {data.tel}
                  </p>
                </Col>
              )}

              {data.googlemap == null ? null : (
                <Col xs={6} sm={6} md={6} lg={6} xl={6} className="text-center">
                  <a
                    href={data.googlemap}
                    target="_blank"
                    className="text-lead text-dark"
                  >
                    <MapPin className="align-middle" size={18} />
                    &nbsp;{data.googlemap}
                  </a>
                </Col>
              )}

              {data.facebook == null ? null : (
                <Col xs={6} sm={6} md={6} lg={6} xl={6} className="text-center">
                  <a
                    href={data.facebook}
                    target="_blank"
                    className="text-lead text-dark"
                  >
                    <Facebook className="align-middle" size={18} />
                    &nbsp;{data.facebook}
                  </a>
                </Col>
              )}

              {data.youtube == null ? null : (
                <Col xs={6} sm={6} md={6} lg={6} xl={6} className="text-center">
                  <a
                    href={data.youtube}
                    target="_blank"
                    className="text-lead text-dark"
                    className="text-lead text-dark"
                  >
                    <Youtube className="align-middle" size={18} />
                    &nbsp;{data.youtube}
                  </a>
                </Col>
              )}
            </Row>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <Row>
              <Col xs={12} sm={12} md={12} lg={9} xl={9}></Col>
              <Col xs={12} sm={12} md={12} lg={3} xl={3} className="text-right">
                <Select
                  type="select"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  name="group_id"
                  value={groups.filter(function (option) {
                    return option.value == group_id || "";
                  })}
                  onChange={(e) => changeGroup(e.value)}
                  options={groups.map((item, i) => ({
                    value: item.value,
                    label: item.label,
                  }))}
                />
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="text-center"
              >
                <h1 className="text-dark font-weight-light">ผลงานที่ผ่านมา</h1>
              </Col>

              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Slider {...settings}>
                  {dataResume.map((item, keys) => (
                    <Card key={keys}>
                      <CardImg
                        style={{
                          padding: "2px 2px 2px 2px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          openImg(item.id);
                        }}
                        className="img-responsive img-fluid rounded mx-auto d-block"
                        src={global.server_url + item.path_img}
                        alt={
                          item.detail == null ? item.group.name : item.detail
                        }
                      />

                      <CardBody>
                        <CardTitle tag="h4" className="textOneLine text-muted">
                          {item.group.name}
                        </CardTitle>
                        <CardText className="textOneLine text-muted">
                          {item.detail}
                        </CardText>
                      </CardBody>
                      <CardFooter className="text-right">
                        <Button
                          size="sm"
                          onClick={() => {
                            openImg(item.id);
                          }}
                        >
                          รายละเอียด
                        </Button>
                      </CardFooter>
                    </Card>
                  ))}
                </Slider>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default Home;
