/*eslint-disable*/

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Container,
  Row,
  Col,
  CardFooter,
  Button,
} from "reactstrap";
// core components
import UserHeader from "components/Headers/UserHeader.js";
import React from "react";
import { ApiGetData, ApiSubmitData } from "../../services/api";
import { AvForm, AvField } from "availity-reactstrap-validation";
const User = () => {
  const [formData, setFormData] = React.useState({});
  const [buttonSubmit, setButtonSubmit] = React.useState(false);

  React.useMemo(() => {
    fetchData();
  }, []);

  async function handleSubmit(event, errors, values) {
    setButtonSubmit(true);

    if (errors.length > 0) {
    } else {
      await ApiSubmitData("api/user", "put", 1, formData);
      fetchData();
    }
    setButtonSubmit(false);
  }

  async function fetchData() {
    await ApiGetData("api/user", "get", 1, null)
      .then(function (response) {
        // console.log(response);
        setFormData(response);
      })
      .catch(function (error) {});
  }

  return (
    <>
      <UserHeader />
      <Container className="mt--7" fluid>
        <AvForm onSubmit={handleSubmit} model={formData}>
          <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col xs="12">
                  <h3 className="mb-0">ข้อมูลผู้เข้าใช้งาน</h3>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Row className="pl-lg-4">
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                  <FormGroup>
                    <AvField
                      name="email"
                      type="text"
                      placeholder="ID"
                      value={formData.email || ""}
                      onChange={(e) =>
                        setFormData({ ...formData, email: e.target.value })
                      }
                      validate={{
                        required: { value: true },
                        pattern: {
                          value: "^[A-Za-z0-9-@]",
                          errorMessage: "ID must be english only",
                        },
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                  <FormGroup>
                    <AvField
                      name="password"
                      type="password"
                      placeholder="Password"
                      value={formData.password || ""}
                      onChange={(e) =>
                        setFormData({ ...formData, password: e.target.value })
                      }
                      validate={{
                        required: { value: false },
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <div className="text-right">
                    <Button
                      disabled={buttonSubmit}
                      className="my-4"
                      color="primary"
                      type="submit"
                      size="md"
                      outline
                    >
                      บันทึก
                    </Button>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </AvForm>
      </Container>
    </>
  );
};

export default User;
