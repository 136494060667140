import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import CookieConsent, { Cookies } from "react-cookie-consent";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
import store from "./store";
import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import { Provider } from "react-redux";

// global.server_url = "http://localhost/twc-server/";
global.server_url = "https://www.cp-twc.co.th/twc-server/";

ReactDOM.render(
  <Provider store={store}>
    <CookieConsent
      location="bottom"
      buttonText="ยืนยัน"
      cookieName="cp-twc-cookie"
      style={{ background: "rgb(250,250,250,0.5)", }}
      buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
      expires={360}
    >
     <span style={{color:'#000000'}}> เราใช้คุ้กกี้เพื่อให้ทุกคนได้ประสบการณ์การใช้งานที่ดียิ่งขึ้น</span>
    </CookieConsent>

    <BrowserRouter>
      <Switch>
        <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
        <Route path="/landing" render={(props) => <AuthLayout {...props} />} />
        <Redirect from="/" to="/landing/home" />
      </Switch>
      {/* </BrowserRouter>, */}
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
