import React from "react";
import { useHistory } from "react-router-dom";

import {
  Card,
  Button,
  CardBody,
  CardHeader,
  CardTitle,
  Container,
  Col,
  Row,
} from "reactstrap";

import * as Alert from "../../customJS/customAlert";
import UserHeader from "components/Headers/UserHeader.js";

import ExampleModal from "../../components/dialogs/dialogDocument";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Edit2, Trash } from "react-feather";
import { ApiGetData, ApiDeleteFile } from "../../services/api";

function Resume() {
  const { SearchBar } = Search;
  const [tableData, setTableData] = React.useState([]);
  const [id, setID] = React.useState(null);
  const [action, setAction] = React.useState("Add");
  const [modal, setModal] = React.useState(false);

  const [reqCallBack, setReqCallBack] = React.useState(null);

  const [actionModal, setActionModel] = React.useState({
    id: id,
    action: action,
    modal: modal,
  });
  const [tableColumns, setTableColumns] = React.useState([
    {
      dataField: "no",
      text: "No",
      sort: true,
      headerStyle: () => {
        return { width: "80px" };
      },
    },

    {
      dataField: "path_img",
      text: "รูป",
      sort: false,
      headerStyle: () => {
        return { width: "80px" };
      },
      formatter: function status(cell, row) {
        // console.log(row);
        return (
          <img
            height="80px"
            onClick={() => eventAction("Edit", row.id)}
            style={{ cursor: "pointer" }}
            src={global.server_url + cell}
            className="img-responsive rounded m-1"
            alt="Responsive image"
          />
        );
      },
    },

    {
      dataField: "name",
      text: "ชื่อไฟล์",
      sort: true,
      headerStyle: () => {
        return { width: "80px" };
      },
    },

    {
      dataField: "active",
      text: "สถานะ",
      sort: true,
      headerStyle: () => {
        return { width: "100px" };
      },
      formatter: function status(cell, row) {
        return cell == "แสดง" ? (
          <span style={{ color: "green" }}>{cell}</span>
        ) : (
          <span style={{ color: "red" }}>{cell}</span>
        );
      },
    },
    {
      dataField: "id",
      text: "จัดการ",
      sort: true,
      headerStyle: () => {
        return { width: "80px" };
      },
      formatter: function action(cell, row) {
        return (
          <div>
            <Button
              onClick={() => eventAction("Edit", cell)}
              className="mr-1 mb-1"
              color="primary"
              outline
              size="sm"
            >
              <Edit2 className="align-middle" size={18} />
            </Button>
            <Button
              onClick={() => deleteItem(cell)}
              className="mr-1 mb-1"
              color="danger"
              outline
              size="sm"
            >
              <Trash className="align-middle" size={18} />
            </Button>
          </div>
        );
      },
    },
  ]);

  async function fetchData() {
    const getData = await ApiGetData("api/document", "get", null, {})
      .then(function (response) {
        response.map(function (item) {
          item.no =
            response
              .map(function (x) {
                return x.id;
              })
              .indexOf(item.id) + 1;
        });
        // console.log(response);
        setTableData(response);
      })
      .catch(function (error) {});
    setReqCallBack(null);
  }
  React.useMemo(() => {
    fetchData();
  }, []);

  React.useEffect(() => {
    if (reqCallBack == "Success") {
      Alert.Top("top-end", "success", "สำเร็จ");
      fetchData();
    } else if (reqCallBack == "Error") {
      Alert.Top("top-end", "error", "ล้มเหลว");
      fetchData();
    }
    return () => {};
  }, [reqCallBack]);

  function eventAction(action, id) {
    setActionModel({
      id: id,
      action: action,
      modal: !modal,
    });
  }

  async function deleteItem(id) {
    var isConfirmed = Alert.Confirmed(
      "ยืนยันการทำรายการ ?",
      "ลบไปแล้วไม่สามารถกู้ข้อมูลกลับมาได้",
      "warning",
      "ยกเลิก",
      "ลบ"
    );
    await isConfirmed.then((result) => {
      if (result == true) {
        const response = ApiDeleteFile("api/document", "delete", id, {});
        response.then(async (res) => {
          if (res.status == 200) {
            Alert.Top("top-end", "success", "สำเร็จ");
          } else {
            Alert.Top("top-end", "error", "ล้มเหลว");
          }
          fetchData();
        });
      }
    });
  }

  return (
    <>
      <UserHeader />
      <Container className="mt--7" fluid>
        <ExampleModal actionModal={actionModal} callBack={setReqCallBack} />
        <Card>
          <ToolkitProvider
            keyField="id"
            data={tableData}
            columns={tableColumns}
            search
          >
            {(props) => (
              <div>
                <CardHeader>
                  <Row>
                    <Col xs={12} sm={12} md={6} lg={6} xl={6}></Col>
                    <Col
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      xl={6}
                      className="text-right"
                    >
                      <Button
                        onClick={() => eventAction("Add", null)}
                        color="secondary"
                        className="float-right mt-n1"
                      >
                        <FontAwesomeIcon icon={faPlus} /> เพิ่ม
                      </Button>
                    </Col>
                  </Row>

                  {/* <CardTitle tag="h5"></CardTitle> */}
                </CardHeader>
                <CardBody>
                  <Row className="mt-2">
                    <Col md={6}></Col>
                    <Col md={6} className="text-right">
                      <SearchBar {...props.searchProps} />
                    </Col>
                  </Row>
                  <BootstrapTable
                    {...props.baseProps}
                    bordered
                    pagination={paginationFactory({
                      sizePerPage: 30,
                      sizePerPageList: [30, 50, 70, 100],
                    })}
                    wrapperClasses="table-responsive" // This is the style provided by bootstrap 4, this will set the parent div with that class
                  />
                </CardBody>
              </div>
            )}
          </ToolkitProvider>
        </Card>
      </Container>
    </>
  );
}
export default Resume;
