// reactstrap components
import { Button, Container, Row, Col, Card, CardBody } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { ApiGetData, ApiSubmitData } from "../../services/api";
// core components
import UserHeader from "components/Headers/UserHeader.js";
import React from "react";
import * as Alert from "../../customJS/customAlert";

const History = () => {
  const [formData, setFormData] = React.useState([]);
  const [buttonSubmit, setButtonSubmit] = React.useState(false);

  React.useMemo(() => {
    fetchData();
  }, []);

  async function handleSubmit(event, errors, values) {
    setButtonSubmit(true);

    if (errors.length > 0) {
    } else {
      const data = new FormData();
      data.append("formData", JSON.stringify(formData));
      const result = await ApiSubmitData("api/history", "post", null, data);
      if (result == 200) {
        Alert.Top("top-end", "success", "สำเร็จ");
      } else {
        Alert.Top("top-end", "error", "ล้มเหลว");
      }
      fetchData();
    }
    setButtonSubmit(false);
  }

  async function fetchData() {
    await ApiGetData("api/history", "get", null, null)
      .then(function (response) {
        // console.log(response);
        setFormData(response);
      })
      .catch(function (error) {});
  }
  function addItem() {
    setFormData([
      ...formData,
      {
        title: "",
        detail: "",
      },
    ]);
  }
  function removeItem(index) {
    // assigning the list to temp variable
    const temp = [...formData];
    // removing the element using splice
    temp.splice(index, 1);
    // updating the list
    setFormData(temp);
  }

  return (
    <>
      <UserHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <AvForm onSubmit={handleSubmit}>
          <Card className="bg-secondary shadow">
            <CardBody>
              {formData.length < 5 ? null : (
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div className="text-right">
                      <Button
                        disabled={buttonSubmit}
                        className="my-4 btn"
                        color="primary"
                        type="submit"
                        size="md"
                      >
                        บันทึก
                      </Button>
                    </div>
                  </Col>
                </Row>
              )}

              {formData.map((item, index) => (
                <Row key={index}>
                  <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                    <AvField
                      name={"title" + [index]}
                      type="text"
                      placeholder="Title"
                      onChange={(e) => {
                        item.title = e.target.value;
                      }}
                      value={item.title}
                      validate={{
                        required: { value: true },
                      }}
                    />
                  </Col>
                  <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                    <Button
                      color="danger"
                      className="mb-3"
                      size="sm"
                      onClick={() => removeItem(index)}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </Button>
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <AvField
                      name={"detail" + [index]}
                      type="textarea"
                      row="2"
                      placeholder="Detail"
                      onChange={(e) => {
                        item.detail = e.target.value;
                      }}
                      value={item.detail}
                      validate={{
                        required: { value: true },
                      }}
                    />
                  </Col>
                </Row>
              ))}
              <Row className="mt-2">
                <Col xs={10} sm={10} md={10} lg={10} xl={10}></Col>
                <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                  <Button color="info" onClick={() => addItem()} size="sm">
                    <FontAwesomeIcon icon={faPlus} />
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <div className="text-right">
                    <Button
                      disabled={buttonSubmit}
                      className="my-4 btn"
                      color="primary"
                      type="submit"
                      size="md"
                    >
                      บันทึก
                    </Button>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </AvForm>
      </Container>
    </>
  );
};

export default History;
