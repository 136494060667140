// reactstrap components
import React from "react";
import { Button, Container, Row, Col } from "reactstrap";
import { CheckToken } from "../../services/api";

const UserHeader = () => {
  return (
    <>
      <div className="header bg-gold pb-8 pt-5 pt-lg-8 d-flex align-items-center">
        {/* Mask */}
      </div>
    </>
  );
};

export default UserHeader;
