// reactstrap components
import React from "react";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
} from "reactstrap";

import { ApiLogin } from "../../services/api";
const Login = () => {
  const [userData, setUserData] = React.useState({
    username: "",
    password: "",
  });
  const [errorMessage, setErrorMessage] = React.useState({ value: "" });

  const handleInputChange = (e) => {
    setUserData((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleSubmit = (e) => {
    localStorage.clear();
    e.preventDefault();
    //if username or password field is empty, return error message
    if (userData.username.length > 0 && userData.password.length > 0) {
      //Signin Success
      var data = new FormData();
      data.append("email", userData.username);
      data.append("password", userData.password);
      data.append("driver", "Web");

      ApiLogin("api/auth/login", "post", data)
        .then(function (response) {
          if (response == 401) {
            setErrorMessage((prevState) => ({
              value: "ชื่อผู้ใช้งาน/รหัสผ่านไม่ถูกต้อง",
            }));
          } else {
            // console.log(response);
            localStorage.setItem("token", response.token);
            localStorage.setItem("isAuthenticated", true);
            window.location.pathname = "/admin";
          }
        })
        .catch(function (error) {});
    } else {
      //If credentials entered is invalid
      setErrorMessage((prevState) => ({ value: "กรุณาใส่ชื่อ/รหัสผ่าน" }));
    }
  };

  return (
    <>
      <Col lg="5" md="7">
        <Card className="card-bg-gold shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <h2>เข้าสู่ระบบ</h2>
            </div>
            <Form role="form">
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    className="form-control"
                    type="text"
                    name="username"
                    placeholder="ชื่อผู้ใช้งาน"
                    onChange={(e) => handleInputChange(e)}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <input
                    className="form-control"
                    type="password"
                    name="password"
                    placeholder="รหัสผ่าน"
                    onChange={(e) => handleInputChange(e)}
                  />
                </InputGroup>
              </FormGroup>
              <div className="text-center">
                <Button
                  className="my-4"
                  color="primary"
                  type="submit"
                  onClick={handleSubmit}
                >
                  เข้าสู่ระบบ
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default Login;
