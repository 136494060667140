// reactstrap components
import { Container, Row, Col } from "reactstrap";
import { useSelector } from "react-redux";
import React from "react";

const Login = () => {
  const dataes = useSelector((state) => state.Get.dataes);

  const [data, setData] = React.useState({});
  React.useMemo(() => {
    if (dataes != null) {
      setData(dataes.home);
    }
  }, [dataes]);

  return (
    <>
      <footer className="py-5">
        <Container>
          <Row className="align-items-center justify-content-xl-between">
            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
              <span style={{ fontSize: "12px" }}>{data.text_address}</span>
            </Col>

            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
              <div className="copyright text-right text-xl-right text-dark">
                <span style={{ fontSize: "12px" }}>
                  © {new Date().getFullYear()}&nbsp; {data.text_footer}
                </span>
                {/* </a> */}
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};

export default Login;
