import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

export function Middle(position, icon, title) {
  MySwal.fire({
    position: position,
    icon: icon,
    title: title,
    showConfirmButton: false,
    timer: 1500,
  });
}
export function Top(position, icon, title) {
  MySwal.fire({
    position: position,
    icon: icon,
    title: title,
    showConfirmButton: false,
    timer: 1500,
  });
}

export async function Confirmed(title, text, icon,cancel,confirm) {
  return new Promise((resolve, reject) => {
    resolve(
      MySwal.fire({
        title: title,
        text: text,
        icon: icon,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: cancel == null ? "ไม่" : cancel,
        confirmButtonText: confirm == null ? "ลบ" : confirm,
      }).then((result) => {
        return result.isConfirmed;
      })
    );
  });
}
