import React from "react";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Col,
  FormGroup,
  Label,
  Row,
  ModalFooter,
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from "reactstrap";

import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";
import Select from "react-select";
import {
  ApiGetData,
  ApiSubmitData,
  ApiUploadFiles,
  ApiDeleteFile,
} from "../../services/api";
import * as Alert from "../../customJS/customAlert";

// const ExampleModal(props) {
const ExampleModal = ({ actionModal, callBack }) => {
  const [id, setID] = React.useState("");
  const [action, setAction] = React.useState("");
  const [modal, setModal] = React.useState(false);
  const [buttonSubmit, setButtonSubmit] = React.useState(false);
  const [btnFileUpload, setBtnFileUpload] = React.useState(false);
  const [formData, setFormData] = React.useState({});
  const [groups, setGroups] = React.useState([]);
  const [slide_imgs, setSlide_imgs] = React.useState([]);
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === slide_imgs.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === 0 ? slide_imgs.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  async function checkAction() {
    setFormData({});
    setSlide_imgs([]);
    const response = await ApiGetData("api/group/select", "get", null, null);

    const options = await response.map((d) => ({
      value: d.id,
      label: d.name,
    }));
    setGroups(options);

    if (actionModal.action == "Add") {
      setFormData({
        active: 1,
      });
    } else if (actionModal.action == "Edit") {
      const response = await ApiGetData(
        "api/resume",
        "get",
        actionModal.id,
        null
      );
      // console.log(response);

      let array = [];
      // console.log(global.server_url);
      for (let index = 0; index < response.slide_imgs.length; index++) {
        array.push({
          id: response.slide_imgs[index].id,
          src: global.server_url + response.slide_imgs[index].img,
          altText: "",
          caption: "",
          header: (
            <div>
              {response.slide_imgs[index].first == 1 ? null : (
                <Col md={12}>
                  <Button
                    size="sm"
                    color="primary"
                    onClick={() => firstImg(response.slide_imgs[index].id)}
                  >
                    ตั้งเป็นรูปหน้าปก
                  </Button>
                  <Button
                    size="sm"
                    className="ml-1"
                    color="danger"
                    onClick={() => deleteImg(response.slide_imgs[index].id)}
                  >
                    ลบ
                  </Button>
                </Col>
              )}
            </div>
          ),
        });
      }
      setSlide_imgs(array);
      let { slide_imgs, ...rest } = response;
      setFormData(rest);
    }
    setID(actionModal.id);
    setAction(actionModal.action);
    setModal(actionModal.modal);
  }

  const handleSubmit = async (event, errors, values) => {
    setButtonSubmit(true);
    if (errors.length > 0) {
    } else {
      const data = new FormData();
      data.append("formData", JSON.stringify(formData));

      if (action == "Add") {
        const response = await ApiSubmitData("api/resume", "post", null, data);
        if (response == 200) {
          callBack("Success");
        } else {
          callBack("Error");
        }
      } else if (action == "Edit") {
        data.append("_method", "PATCH");
        const response = await ApiSubmitData("api/resume", "post", id, data);
        if (response === 200) {
          callBack("Success");
        } else {
          callBack("Error");
        }
      }
      setModal(!modal);
    }
    setButtonSubmit(false);
  };

  React.useMemo(() => {
    if (actionModal.modal) {
      checkAction();
    }
    return () => {};
  }, [actionModal]); // Only re-run the effect if count changes

  async function onChangePicture_img(e) {
    if (e.target.files[0]) {
      const files = e.target.files;
      const data = new FormData();
      if (formData.id != undefined) {
        data.append("resume_id", formData.id);
      }
      for (let i = 0; i < files.length; i++) {
        data.append(`slide_imgs[${i}]`, files[i]);
      }
      setBtnFileUpload(true);
      const response = await ApiUploadFiles(
        "api/resume/slide_imgs",
        "post",
        null,
        data
      );
      // console.log(response);

      if (response.status == 200) {
        callBack("Success");

        if (formData.id == undefined) {
          if (response.data.resume_id != null) {
            setFormData({ ...formData, id: response.data.resume_id });
          }
        }
        let array = [];
        for (let index = 0; index < response.data.slide_imgs.length; index++) {
          array.push({
            id: response.data.slide_imgs[index].id,
            src: global.server_url + response.data.slide_imgs[index].img,
            altText: "",
            caption: "",
            header: (
              <div>
                {response.data.slide_imgs[index].first == 1 ? null : (
                  <Col md={12}>
                    <Button
                      size="sm"
                      color="primary"
                      onClick={() =>
                        firstImg(response.data.slide_imgs[index].id)
                      }
                    >
                      ตั้งเป็นรูปหน้าปก
                    </Button>

                    <Button
                      size="sm"
                      className="ml-1"
                      color="danger"
                      onClick={() =>
                        deleteImg(response.data.slide_imgs[index].id)
                      }
                    >
                      ลบ
                    </Button>
                  </Col>
                )}
              </div>
            ),
          });
        }
        setSlide_imgs(array);
      } else {
        callBack("Error");
      }

      setBtnFileUpload(false);
    }
  }

  async function deleteImg(id) {
    var isConfirmed = Alert.Confirmed(
      "ยืนยันการทำรายการ ?",
      "ลบไปแล้วไม่สามารถกู้ข้อมูลกลับมาได้",
      "warning"
    );
    await isConfirmed.then((result) => {
      if (result == true) {
        const response = ApiDeleteFile("api/resume_img", "delete", id, {});
        response.then(async (res) => {
          if (res.status == 200) {
            callBack("Success");
            // checkAction();

            let array = [];
            // console.log(global.server_url);
            for (let index = 0; index < res.data.slide_imgs.length; index++) {
              array.push({
                id: res.data.slide_imgs[index].id,
                src: global.server_url + res.data.slide_imgs[index].img,
                altText: "",
                caption: "",
                header: (
                  <div>
                    {res.data.slide_imgs[index].first == 1 ? null : (
                      <Col md={12}>
                        <Button
                          size="sm"
                          color="primary"
                          onClick={() =>
                            firstImg(res.data.slide_imgs[index].id)
                          }
                        >
                          ตั้งเป็นรูปหน้าปก
                        </Button>

                        <Button
                          size="sm"
                          className="ml-1"
                          color="danger"
                          onClick={() =>
                            deleteImg(res.data.slide_imgs[index].id)
                          }
                        >
                          ลบ
                        </Button>
                      </Col>
                    )}
                  </div>
                ),
              });
            }
            setSlide_imgs(array);
          } else {
            callBack("Error");
          }
        });
      }
    });
  }

  async function firstImg(id) {
    const response = ApiDeleteFile("api/resume_img/first_img", "get", id, {});
    response.then(async (res) => {
      if (res.status == 200) {
        callBack("Success");
        // checkAction();

        let array = [];
        // console.log(res.data.slide_imgs);
        for (let index = 0; index < res.data.slide_imgs.length; index++) {
          array.push({
            id: res.data.slide_imgs[index].id,
            src: global.server_url + res.data.slide_imgs[index].img,
            altText: "",
            caption: "",
            header: (
              <div>
                {res.data.slide_imgs[index].first == 1 ? null : (
                  <Col md={12}>
                    <Button
                      size="sm"
                      color="primary"
                      onClick={() => firstImg(res.data.slide_imgs[index].id)}
                    >
                      ตั้งเป็นรูปหน้าปก
                    </Button>

                    <Button
                      size="sm"
                      className="ml-1"
                      color="danger"
                      onClick={() => deleteImg(res.data.slide_imgs[index].id)}
                    >
                      ลบ
                    </Button>
                  </Col>
                )}
              </div>
            ),
          });
        }
        setSlide_imgs(array);
      } else {
        callBack("Error");
      }
    });
  }
  async function checkCloseModal() {
    // console.log(formData);
    if (slide_imgs.length > 0 && action == "Add") {
      var isConfirmed = Alert.Confirmed(
        "ยืนยันการออก ?",
        "มีรูปที่กำลังรอการอัพโหลด",
        "warning",
        "อยู่ต่อ",
        "ออก"
      );
      await isConfirmed.then((result) => {
        if (result == true) {
          const response = ApiDeleteFile(
            "api/resume/delete",
            "get",
            formData.id,
            {}
          );
          response.then(async (res) => {
            // console.log(res);
            if (res.status == 200) {
              setModal(!modal);
            }
          });
        }
      });
    } else {
      setModal(!modal);
    }
  }
  return (
    <Modal isOpen={modal} toggle={checkCloseModal} centered={true} size="lg">
      <ModalHeader toggle={checkCloseModal}>
        {action == "Edit" ? "แก้ไข" : "เพิ่ม"}ข้อมูล
      </ModalHeader>
      <AvForm onSubmit={handleSubmit} model={formData}>
        <ModalBody>
          {/* {action == "Edit" ? ( */}
          <div>
            <Row className="mt-2 text-center">
              {slide_imgs.length > 0 ? (
                <Col
                  md={12}
                  style={{
                    width: "100%",
                  }}
                >
                  {/* <PictureSlider /> */}
                  <Carousel
                    activeIndex={activeIndex}
                    next={next}
                    previous={previous}
                  >
                    <CarouselIndicators
                      items={slide_imgs}
                      activeIndex={activeIndex}
                      onClickHandler={goToIndex}
                    />
                    {slide_imgs.map((item) => {
                      return (
                        <CarouselItem
                          onExiting={() => setAnimating(true)}
                          onExited={() => setAnimating(false)}
                          key={item.id}
                        >
                          <img
                            className="img-thumbnail img-responsive mt-2"
                            style={{ cursor: "pointer" }}
                            src={item.src}
                            alt={item.altText}
                            onClick={() => {
                              // console.log(Img);
                              window.open(item.src, "_blank");
                            }}
                          />

                          <CarouselCaption
                            captionText={item.caption || ""}
                            captionHeader={item.header}
                          />
                        </CarouselItem>
                      );
                    })}
                    <CarouselControl
                      direction="prev"
                      directionText="Previous"
                      onClickHandler={previous}
                    />
                    <CarouselControl
                      direction="next"
                      directionText="Next"
                      onClickHandler={next}
                    />
                  </Carousel>
                </Col>
              ) : null}
            </Row>
            <br />
            <Row>
              <Col xs={12} style={{ textAlignLast: "center" }}>
                <FormGroup>
                  <Label>File Image</Label>

                  <AvInput
                    disabled={btnFileUpload}
                    accept="image/*"
                    type="file"
                    multiple={true}
                    name="file_img"
                    onChange={onChangePicture_img}
                  />
                </FormGroup>
              </Col>
            </Row>
          </div>
          {/* ) : null} */}

          <Row>
            <Col xs={7} sm={7} md={7} lg={7} xl={7}>
              <FormGroup>
                <Select
                  type="select"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  name="group_id"
                  value={groups.filter(function (option) {
                    return option.value == formData.group_id || "";
                  })}
                  onChange={(e) => (formData.group_id = e.value)}
                  options={groups.map((item, i) => ({
                    value: item.value,
                    label: item.label,
                  }))}
                />

                {/* <AvField
                  type="select"
                  name="group_id"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      group_id: e.target.value,
                    })
                  }
                  value={formData.group_id}
                  validate={{
                    required: { value: true },
                  }}
                >
                  <option hidden>กลุ่ม</option>
                  {groups.map((item, i) => (
                    <option key={i} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </AvField> */}
              </FormGroup>
            </Col>

            <Col xs={5} sm={5} md={5} lg={5} xl={5}>
              <FormGroup className="mt-2">
                <FormGroup check inline>
                  <Label check>
                    <Input
                      type="radio"
                      name="radioActive"
                      checked={formData.active == 1}
                      value={1}
                      onChange={(e) =>
                        setFormData({ ...formData, active: e.target.value })
                      }
                    />
                    แสดงหน้าร้าน
                  </Label>
                </FormGroup>
                <FormGroup check inline>
                  <Label>
                    <Input
                      type="radio"
                      name="radioActive"
                      checked={formData.active == 2}
                      value={2}
                      onChange={(e) =>
                        setFormData({ ...formData, active: e.target.value })
                      }
                    />
                    ไม่แสดง
                  </Label>
                </FormGroup>
              </FormGroup>
            </Col>

            <Col xs={12} sm={12} md={12} lg={12}>
              <FormGroup>
                <AvField
                  name="detail"
                  type="textarea"
                  rows="2"
                  placeholder="Detail"
                  value={formData.detail}
                  onChange={(e) =>
                    setFormData({ ...formData, detail: e.target.value })
                  }
                  validate={{
                    required: { value: false },
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button type="submit" disabled={buttonSubmit} color="secondary">
            บันทึก
          </Button>
        </ModalFooter>
      </AvForm>
    </Modal>
  );
};

export default ExampleModal;
