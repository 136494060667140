// reactstrap components
import React from "react";

import { Card, CardHeader, CardBody, Row, Col } from "reactstrap";
import { useSelector } from "react-redux";
import { Hash } from "react-feather";
import ExampleModal from "../../components/dialogs/dialogImg";

const History = () => {
  const [data, setData] = React.useState([]);
  const dataes = useSelector((state) => state.Get.dataes);

  const [actionModal, setActionModel] = React.useState({
    action: "Edit",
    name: "",
    img: null,
    modal: false,
  });
  React.useMemo(() => {
    if (dataes != null) {
      // console.log(dataes.document);
      setData(dataes.document);
    }
  }, [dataes]);
  async function openImg(id) {
    let new_img = [];
    let new_name = "";
    for (let index = 0; index < data.length; index++) {
      if (data[index].id == id) {
        // console.log(data[index]);
        new_name = data[index].name;
        new_img.push({
          id: index,
          src: global.server_url + data[index].path_img,
          altText: new_name,
        });
      }
    }
    setActionModel({
      action: "Edit",
      name: new_name,
      img: new_img,
      modal: true,
    });
  }
  return (
    <>
      <Col xs={12} sm={12} md={12} lg={7} xl={7}>
        <ExampleModal actionModal={actionModal} />

        <Card className="card-bg-gold shadow border-0">
          <CardHeader className="bg-transparent">
            <Row className="justify-content-center">
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="text-center"
              >
                <h1 className="text-dark font-weight-light">บริการของเรา</h1>
              </Col>
            </Row>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            {data.map((item, keys) => (
              <div key={keys}>
                <Row>
                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="text-center"
                  >
                    <h3 className="text-dark font-weight-light">
                      <Hash className="align-middle mb-1" size={18} />
                      &nbsp; {item.name}
                    </h3>

                    {item.img == null ? null : (
                      <img
                        onClick={() => openImg(item.id)}
                        style={{
                          cursor: "pointer",
                          width: "35vw",
                          height: "30vw",
                        }}
                        alt={item.name}
                        src={global.server_url + item.path_img}
                      />
                    )}
                  </Col>
                </Row>
                <hr />
              </div>
            ))}
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default History;
