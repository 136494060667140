import React from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
import { Container, Row, Col } from "reactstrap";
import { AnimateKeyframes, Animate, AnimateGroup } from "react-simple-animate";

// core components
import AuthNavbar from "components/Navbars/AuthNavbar.js";
import AuthFooter from "components/Footers/AuthFooter.js";
import { useDispatch } from "react-redux";
import { getAll } from "../store/Actions/TestActions";
import routes from "routes.js";
import logo from "../assets/img/brand/logo.png";
import { useSelector } from "react-redux";
const Half = (props) => {
  const [loading, setLoding] = React.useState(true);
  const dataes = useSelector((state) => state.Get.dataes);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getAll());
  }, []);

  React.useMemo(() => {
    if (dataes != null) {
      setLoding(false);
    }
  }, [dataes]);

  const mainContent = React.useRef(null);
  const location = useLocation();
  React.useEffect(() => {
    document.body.classList.add("bg-gold");
    document.body.classList.add("text-dark");
    // document.body.classList.add("textOneLine");

    return () => {
      document.body.classList.remove("bg-gold");
      document.body.classList.remove("text-dark");
      // document.body.classList.remove("textOneLine");
    };
  }, []);
  // React.useEffect(() => {
  //   document.documentElement.scrollTop = 0;
  //   document.scrollingElement.scrollTop = 0;
  //   mainContent.current.scrollTop = 0;
  // }, [location]);

  const getRoutes = (routes) => {
    // console.log(routes);
    return routes.map((prop, key) => {
      if (location.pathname == "/landing" || location.pathname == "/landing/") {
        window.location.pathname = "/";
      } else {
        if (prop.layout === "/landing") {
          return (
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
          );
        } else {
          return null;
        }
      }
    });
  };

  return loading == true ? (
    <>
      <div className="centered">
        <Row className="justify-content-center">
          <Col className="text-center">
            {/* <AnimateKeyframes
              play
              iterationCount="infinite"
              duration={5}
              keyframes={[
                { 0: "transform: rotateZ(0)" }, // 0%
                { 50: "transform: rotateZ(180deg)" }, // 50%
                { 100: "transform: rotateZ(360deg)" }, // 100%
              ]}
            >
              <img
                src={logo}
                style={{ width: "250px", height: "250px" }}
                className="img-responsive img-fluid rounded mx-auto d-block img-fluid rounded-circle"
                alt="Premium House"
              />
            </AnimateKeyframes> */}
            <br />
            <h3>C&P THAWEECHOK CO..LTD</h3>
          </Col>
        </Row>
      </div>
    </>
  ) : (
    <>
      <AuthNavbar />

      <div className="main-content" ref={mainContent}>
        <div className="header py-7 py-lg-7">
          {/* <div className="separator separator-bottom separator-skew zindex-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="fill-default"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div> */}
        </div>
        {/* Page content */}
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Switch>
              {getRoutes(routes)}
              {/* <Redirect from="*" to="/" /> */}
            </Switch>
          </Row>
        </Container>
        <AuthFooter />
      </div>
    </>
  );
};

export default Half;
