import { ApiLanding } from "../../services/api";

export const getAll = () => {
  return (dispatch) => {
    ApiLanding()
      .then(function (response) {
        dispatch({ type: "DO_THIS", payload: response });
      })
      .catch(function (error) {});
  };
};
